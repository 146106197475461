/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";
import { AlertDone } from "../../components/request-response";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

interface ChartData {
  total_jobs: number;
  enqueued: number;
  failed: number;
  ready: number;
  deadLetter: number;
}

export default function RosieChartsView() {
  const [configList, setConfigList] = useState([]);
  const [chartData, setChartData] = useState<ChartData>({
    total_jobs: 0,
    enqueued: 0,
    failed: 0,
    ready: 0,
    deadLetter:0,
  });
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be executed");
  const [status, setStatus] = useState(400);
  const [selectedIndexer, setSelectedIndexer] = useState("");
  const [selectedTransformer, setSelectedTransformer] = useState("");
  const [selectedHours, setSelectedHours] = useState(1);

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    fetchCharts();
  }, [selectedHours, selectedIndexer, selectedTransformer]);

  const handleCloseAlert = () => {
    setShowExec(false);
  };

  const fetchConfigs = async () => {
    const options = {
      method: "GET",
      url: `${api_url}manager/configs/all`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };

    try {
      const response = await axios.request(options);
      setConfigList(response.data);
    } catch (error) {
      console.log(error);
      setConfigList([]);
    }
  };

  const fetchCharts = async () => {
    const payload = {
      timestamp: new Date(
        new Date().setHours(new Date().getHours() - selectedHours)
      ).toISOString(),
      ...(selectedIndexer && { indexer: selectedIndexer }),
      ...(selectedTransformer && { transformer: selectedTransformer }),
    };
    const message = {
      message: {
        data: JSON.stringify(payload),
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}manager/rosie/insights`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setChartData(response.data);
        setShowExec(false);
      })
      .catch(function (error) {
        setMessage(`Error: ${error.response?.data || "Unknown error"}`);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };

  const handleHourChange = (hour) => {
    setSelectedHours(hour);
  };

  const handleIndexerChange = (e) => {
    setSelectedIndexer(e.target.value);
    setSelectedTransformer("");
  };

  const handleTransformerChange = (e) => {
    setSelectedTransformer(e.target.value);
  };

  const indexers = Array.from(
    new Set(configList?.map((item) => item.indexer) ?? [])
  );

  const transformers = configList
    .filter((item) => item.indexer === selectedIndexer)
    .map((item) => item.transformer);

  const generateChartData = (label, label2, count, total) => ({
    labels: [label, label2],
    datasets: [
      {
        data: [count, total - count],
        backgroundColor: ["rgba(34, 197, 94, 0.5)", "rgba(200, 200, 200, 0.5)"],
        borderColor: ["rgba(34, 197, 94, 1)", "rgba(200, 200, 200, 1)"],
        borderWidth: 1,
      },
    ],
  });

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <div className="w-full">
        <div className="flex justify-between items-center w-full mb-4">
          <div className="flex space-x-2">
            {[1, 3, 5, 8, 24, 48, 72].map((hour) => (
              <button
                key={hour}
                onClick={() => handleHourChange(hour)}
                className={`px-4 py-2 rounded-md ${
                  selectedHours === hour
                    ? "bg-green-600 text-white"
                    : "bg-green-200"
                }`}
              >
                {hour}h
              </button>
            ))}
          </div>
          <h1 className="text-3xl font-semibold">Rosie Metrics</h1>
        </div>

        <div className="flex gap-4 mb-4">
          <select
            value={selectedIndexer}
            onChange={handleIndexerChange}
            className="p-2 border border-gray-300 rounded"
          >
            <option value="">All Indexers</option>
            {indexers.map((indexer) => (
              <option key={indexer} value={indexer}>
                {indexer}
              </option>
            ))}
          </select>
          <select
            value={selectedTransformer}
            onChange={handleTransformerChange}
            className="p-2 border border-gray-300 rounded"
            disabled={!selectedIndexer}
          >
            <option value="">All Transformers</option>
            {transformers.map((transformer) => (
              <option key={transformer} value={transformer}>
                {transformer}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center mb-4">
          <div
            style={{ textAlign: "center", margin: "auto", fontSize: "2rem" }}
          >
            <div>Total Jobs</div>
            <div style={{ fontWeight: "bold" }}>{chartData.total_jobs}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flexBasis: "25%", minWidth: "250px", textAlign: "center" }}>
            <h3>Enqueued</h3>
            <Doughnut
              data={generateChartData(
                "Enqueued",
                "Other Jobs",
                chartData.enqueued,
                chartData.total_jobs
              )}
            />
          </div>
          <div style={{ flexBasis: "25%", minWidth: "250px", textAlign: "center"}}>
            <h3>Failed</h3>
            <Doughnut
              data={generateChartData(
                "Failed",
                "Other Jobs",
                chartData.failed,
                chartData.total_jobs
              )}
            />
          </div>
          <div style={{ flexBasis: "25%", minWidth: "250px", textAlign: "center"}}>
            <h3>Ready</h3>
            <Doughnut
              data={generateChartData(
                "Ready",
                "Other Jobs",
                chartData.ready,
                chartData.total_jobs
              )}
            />
          </div>
          <div style={{ flexBasis: "25%", minWidth: "250px", textAlign: "center"}}>
            <h3>Dead-letter</h3>
            <Doughnut
              data={generateChartData(
                "Dead-letter",
                "Regular Jobs",
                chartData.deadLetter,
                chartData.total_jobs
              )}
            />
          </div>
        </div>

        {showExec && (
          <AlertDone
            message={message}
            status={status}
            open={showExec}
            onClose={handleCloseAlert}
          />
        )}
      </div>
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface Flow {
  _id: string;
  indexer: string;
  name: string;
  flow: string;
  updatedAt: string;
}

export default function FlowsView() {
  const [flows, setFlows] = useState<Flow[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFlowId, setSelectedFlowId] = useState<string | null>(null);
  const [editedFlow, setEditedFlow] = useState<Flow | null>(null);
  const [changesMade, setChangesMade] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFlowsData();
  }, []);

  const fetchFlowsData = async () => {
    const options = {
      method: "GET",
      url: `${api_url}manager/console/flowsAll`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };
    try {
      const response = await axios.request(options);
      const dataWithId = response.data.map((flow: any) => ({
        ...flow,
        _id: flow._id.$oid || flow._id,
        updatedAt: flow.updatedAt
          ? new Date(flow.updatedAt.$date || flow.updatedAt).toISOString()
          : "",
      }));
      setFlows(dataWithId);
    } catch (error) {
      console.error("Error fetching flows data", error);
    }
  };

  const handleFlowClick = (flow: Flow) => {
    if (selectedFlowId === flow._id) {
      // Collapse if already selected
      setSelectedFlowId(null);
      setEditedFlow(null);
      setChangesMade(false);
    } else {
      setSelectedFlowId(flow._id);
      setEditedFlow({ ...flow }); // Create a copy for editing
      setChangesMade(false);
    }
  };

  const handleInputChange = (field: keyof Flow, value: string) => {
    if (editedFlow) {
      setEditedFlow({
        ...editedFlow,
        [field]: value,
      });
      setChangesMade(true);
    }
  };

  const handleSaveChanges = async () => {
    if (!editedFlow) return;

    setLoading(true);

    const payload = {
      message: {
        data: JSON.stringify({
          _id: editedFlow._id,
          indexer: editedFlow.indexer,
          name: editedFlow.name,
          flow: editedFlow.flow,
        }),
      },
    };

    const options = {
      method: "POST",
      url: `${api_url}manager/console/flowsUpdate`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: payload,
    };

    try {
      await axios.request(options);
      // Update the flow in the local state
      setFlows((prevFlows) =>
        prevFlows.map((flow) =>
          flow._id === editedFlow._id ? editedFlow : flow
        )
      );
      setChangesMade(false);
      alert("Flow updated successfully");
    } catch (error) {
      console.error("Error updating flow", error);
      alert("Failed to update flow");
    } finally {
      setLoading(false);
    }
  };

  const filteredFlows = flows.filter(
    (flow) =>
      flow.indexer.toLowerCase().includes(searchQuery.toLowerCase()) ||
      flow.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      flow.flow.toLowerCase().includes(searchQuery.toLowerCase()) ||
      flow._id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      <h1 className="text-3xl font-semibold my-6 text-center">
        Flows Management
      </h1>
      <div className="w-full">
        <div className="mb-4">
          <div className="relative flex items-center">
            <input
              type="text"
              placeholder="Search Flows"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 pl-10 border border-gray-300 rounded focus:outline-none focus:border-green-200"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 absolute left-3 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
        </div>

        {filteredFlows.map((flow) => (
          <div
            key={flow._id}
            className="bg-green-200 p-4 rounded-md cursor-pointer mb-4"
          >
            <div
              className="flex items-center"
              onClick={() => handleFlowClick(flow)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 mr-2 transform transition-transform ${
                  selectedFlowId === flow._id ? "rotate-90" : ""
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5l7 7-7 7M5 5l7 7-7 7"
                />
              </svg>
              <p className="text-lg font-semibold">
                {flow.indexer} - {flow._id}
              </p>
            </div>
            {selectedFlowId === flow._id && editedFlow && (
              <div className="mt-2 p-4 rounded-md bg-green-100">
                <div className="mb-2">
                  <label className="block text-gray-700 font-semibold">
                    Flow Id:
                  </label>
                  <input
                    type="text"
                    value={flow._id}
                    disabled
                    className="w-full p-2 rounded focus:outline-none bg-green-100"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 font-semibold">
                    Name:
                  </label>
                  <input
                    type="text"
                    value={editedFlow.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                    className="w-full p-2 border border-white rounded focus:outline-none bg-green-100"
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 font-semibold">
                    Indexer:
                  </label>
                  <input
                    type="text"
                    value={editedFlow.indexer}
                    onChange={(e) =>
                      handleInputChange("indexer", e.target.value)
                    }
                    className="w-full p-2 border border-white rounded focus:outline-none bg-green-100"
                    autoFocus
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 font-semibold">
                    Flow:
                  </label>
                  <textarea
                    value={editedFlow.flow}
                    onChange={(e) => handleInputChange("flow", e.target.value)}
                    className="w-full p-2 border border-white rounded focus:outline-none bg-green-100"
                    rows={5}
                  />
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700 font-semibold">
                    Updated At:
                  </label>
                  <input
                    type="text"
                    value={
                      editedFlow.updatedAt
                        ? new Date(editedFlow.updatedAt).toLocaleString()
                        : ""
                    }
                    disabled
                    className="w-full p-2 rounded focus:outline-none bg-green-100"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={handleSaveChanges}
                    className={`px-4 py-2 text-white rounded ${
                      !changesMade || loading
                        ? "bg-blue-300 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                    disabled={!changesMade || loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
}

export const services = [
  "backfill",
  "batch-writter",
  "indexer",
  "jiti",
  "logger",
  "long-runner",
  "oscar",
  "rosie",
  "manager",
];

export const environments = ["production", "deployment"];

export const hosts = ["cloud-run", "vm-instance", "VM-Instance-Group"];

export const levels = ["info", "debug", "warn", "error"];

import React, { useState } from "react";
import { Button, Card } from "@material-tailwind/react";
import axios from "axios";
import { AlertDone } from "../../components/request-response";
import { api_url } from "../../index";

export default function PacemakerBackfillView() {
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be excecuted");
  const [status, setStatus] = useState(400);
  const handleCloseAlert = () => {
    setShowExec(false);
  };
  const handleform = async (e) => {
    e.preventDefault();
    var indexer = e.target.elements.indexer.value;
    var name = e.target.elements.transformer.value;
    var pacemaker = e.target.elements.pacemaker.value;
    var start = e.target.elements.start.value;
    var end = e.target.elements.end.value;

    const message = {
      message: {
        data: `{"indexer": "${indexer}", "transformer": "${name}", "pacemaker":"${pacemaker}", "start":"${start}", "end":"${end}"}\n`,
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}manager/scripts/pacemakerbackfill`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setMessage(response.data);
        setStatus(response.status);
        setShowExec(true);
      })
      .catch(function (error) {
        setMessage(error.response.data);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };

  return (
    <>
      <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto items-center">
        <div className="text-center mt-4 mb-4">
          <h1 className="text-3xl font-semibold mb-6">Run Pacemaker Backfill</h1>
        </div>
        <form onSubmit={handleform} className="w-full max-w-lg">
          <div className="w-full">
            <div className="mb-4">
              <div className="flex flex-wrap items-center">
                <div className="w-full md:w-1/2 px-3 mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center"
                    htmlFor="indexer"
                  >
                    Indexer
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="indexer"
                    type="text"
                    placeholder="Indexer"
                  />
                  <p className="text-green-200 text-xs italic">
                    Please fill out this field.
                  </p>
                </div>

                <div className="w-full md:w-1/2 px-3 mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center"
                    htmlFor="transformer"
                  >
                    Transformer
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="transformer"
                    type="text"
                    placeholder="Transformer"
                  />
                  <p className="text-green-200 text-xs italic">
                    Please fill out this field.
                  </p>
                </div>

                <div className="w-full md:w-1/3 px-3 mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center"
                    htmlFor="pacemaker"
                  >
                    PaceMaker
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="pacemaker"
                    type="text"
                    placeholder="PaceMaker"
                  />
                  <p className="text-green-200 text-xs italic">
                    Please fill out this field.
                  </p>
                </div>

                <div className="w-full md:w-1/3 px-3 mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center"
                    htmlFor="start"
                  >
                    Start
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="start"
                    type="number"
                    placeholder="Start"
                  />
                  <p className="text-green-200 text-xs italic">
                    Please fill out this field.
                  </p>
                </div>

                <div className="w-full md:w-1/3 px-3 mb-6">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center"
                    htmlFor="end"
                  >
                    End
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="end"
                    type="number"
                    placeholder="End"
                  />
                  <p className="text-green-200 text-xs italic">
                    Please fill out this field.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <Button variant="gradient" color="light-green" type="submit">
              Submit
            </Button>
          </div>
        </form>

        {showExec && (
          <AlertDone
            message={message}
            status={status}
            open={showExec}
            onClose={handleCloseAlert}
          />
        )}
      </Card>
    </>
  );
}

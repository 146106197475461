import React, { useState } from "react";
import { Button, Card } from "@material-tailwind/react";
import axios from "axios";
import { AlertDone } from "../../components/request-response";
import {api_url} from "../../index"

export default function DeleteConfigView() {
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be excecuted");
  const [status, setStatus] = useState(400);
  const handleCloseAlert = () => {
    setShowExec(false);
  };
  const handleform = async (e) => {
    e.preventDefault();
    var indexer = e.target.elements.indexer.value;
    var transformer = e.target.elements.transformer.value;
    var feedtype = e.target.elements.feedtype.value;
    const message = {
      message: {
        data: `{"indexer": "${indexer}", "transformer": "${transformer}", "feedType": "${feedtype}"}\n`,
      },
    };

    const options = {
      method: "POST",
      url: `${api_url}manager/configs/delete`,
      headers: {
        "authorization": `Bearer ${sessionStorage.getItem('jwt')}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setMessage(response.data);
        setStatus(response.status);
        setShowExec(true);
      })
      .catch(function (error) {
        setMessage(`${error.response.data}`);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };

  return (
    <>
      <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto items-center">
        <div className="text-center mt-4 mb-4">
          <h1 className="text-3xl font-semibold mb-6">Delete Config</h1>
        </div>
        <form onSubmit={handleform} className="w-full max-w-lg items-center">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Indexer
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="indexer"
                type="text"
                placeholder="indexer"
              />
              <p className="text-green-200 text-xs italic">
                Please fill out this field.
              </p>
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Transformer
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="transformer"
                type="text"
                placeholder="transformer"
              />
              <p className="text-green-200 text-xs italic">
                Please fill out this field.
              </p>
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-state"
              >
                FeedType
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="feedtype"
                >
                  <option>ARWEAVE</option>
                  <option>CRON</option>
                  <option>EVM</option>
                  <option>HEARTBEAT</option>
                  <option>TEZOS</option>
                  <option>TRANSFORMER</option>
                  <option>WEBHOOK</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <Button variant="gradient" color="light-green" type="submit">
              Submit
            </Button>
          </div>
        </form>
        {showExec && (
          <AlertDone
            message={message}
            status={status}
            open={showExec}
            onClose={handleCloseAlert}
          />
        )}
      </Card>
    </>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import FlowsView from "./flows";

export default function IndexConsoleView() {
    const { consoleAction } = useParams();

  return (
    <div>
    {consoleAction === "flows" && <FlowsView/>}
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { api_url } from "../../index";

interface NetworkConnection {
  network: string;
  host: string;
  rps_limit: number | null;
  connection_type: string;
  enabled: boolean;
  id?: string;
}

export default function NetworksConnections() {
  const [connections, setConnections] = useState<NetworkConnection[]>([]);
  const [editing, setEditing] = useState<string | null>(null);
  const [adding, setAdding] = useState<boolean>(false); // New state for adding mode
  const [changesMade, setChangesMade] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const editingRowRef = useRef<HTMLTableRowElement | null>(null);

  const initialNewConnection: NetworkConnection = {
    network: "",
    host: "",
    rps_limit: null,
    connection_type: "",
    enabled: true,
    id: uuidv4(),
  };

  const [newConnection, setNewConnection] =
    useState<NetworkConnection>(initialNewConnection);

  useEffect(() => {
    fetchConnectionsData();
  }, []);

  useEffect(() => {
    if (editing) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editing]);

  const fetchConnectionsData = async () => {
    const options = {
      method: "GET",
      url: `${api_url}manager/oscar/networksconnections`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };
    try {
      const response = await axios.request(options);
      const dataWithId = response.data.map((conn: any) => ({
        ...conn,
        id: uuidv4(),
      }));
      setConnections(dataWithId);
    } catch (error) {
      console.error("Error fetching network connections data", error);
    }
  };

  const handleSaveChanges = async (connection: NetworkConnection) => {
    const payload = {
      message: {
        data: JSON.stringify({
          network_key: connection.network,
          host: connection.host,
          connection_type: connection.connection_type,
          rps_limit: connection.rps_limit,
          enabled: connection.enabled,
        }),
      },
    };

    const options = {
      method: "POST",
      url: `${api_url}manager/oscar/setnetworkconnection`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: payload,
    };

    try {
      await axios.request(options);
      setEditing(null);
      setChangesMade(false);
      fetchConnectionsData();
    } catch (error) {
      console.error("Error saving network connection", error);
      setEditing(null);
      setChangesMade(false);
      fetchConnectionsData();
    }
  };

  const handleRowClick = (id: string) => {
    if (!adding) {
      // Prevent editing when adding a new connection
      setEditing(id);
      setChangesMade(false);
    }
  };

  const handleInputChange = (id: string, field: string, value: any) => {
    setConnections((prevConnections) =>
      prevConnections.map((conn) =>
        conn.id === id ? { ...conn, [field]: value } : conn
      )
    );
    setChangesMade(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      editingRowRef.current &&
      !editingRowRef.current.contains(event.target as Node)
    ) {
      setEditing(null);
      setChangesMade(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setEditing(null);
      setChangesMade(false);
    }
  };

  const handleNewConnectionChange = (field: string, value: any) => {
    setNewConnection((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddNewConnection = async () => {
    const payload = {
      message: {
        data: JSON.stringify({
          network_key: newConnection.network,
          host: newConnection.host,
          connection_type: newConnection.connection_type,
          rps_limit: newConnection.rps_limit,
          enabled: newConnection.enabled,
        }),
      },
    };

    const options = {
      method: "POST",
      url: `${api_url}manager/oscar/setnetworkconnection`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: payload,
    };

    try {
      await axios.request(options);
      setAdding(false);
      setNewConnection(initialNewConnection);
      fetchConnectionsData();
    } catch (error) {
      console.error("Error adding new network connection", error);
    }
  };

  // Filter connections based on search query
  const filteredConnections = connections.filter((conn) => {
    const rpsLimitStr =
      conn.rps_limit !== null ? conn.rps_limit.toString() : "Infinite";
    return (
      conn.network.toLowerCase().includes(searchQuery.toLowerCase()) ||
      conn.host.toLowerCase().includes(searchQuery.toLowerCase()) ||
      conn.connection_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      rpsLimitStr.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold mr-4">Networks Connections</h1>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded mr-4"
            onClick={() => setAdding(true)}
            disabled={adding || editing !== null}
          >
            Add New RPC
          </button>
        </div>
        <input
          type="text"
          placeholder="Search Networks"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-1/3 p-2 pl-4 border border-gray-300 rounded"
        />
      </div>
      <div className="w-full h-full overflow-x-auto mt-2">
        <table className="w-full">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2">Network</th>
              <th className="py-2">Host</th>
              <th className="py-2">Connection Type</th>
              <th className="py-2">RPS Limit</th>
              <th className="py-2">Enabled</th>
              {(editing || adding) && <th className="py-2">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {adding && (
              <tr className="bg-green-100">
                <td className="py-2 text-center">
                  <input
                    type="text"
                    value={newConnection.network}
                    onChange={(e) =>
                      handleNewConnectionChange("network", e.target.value)
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                    autoFocus
                  />
                </td>
                <td className="py-2 text-center">
                  <input
                    type="text"
                    value={newConnection.host}
                    onChange={(e) =>
                      handleNewConnectionChange("host", e.target.value)
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </td>
                <td className="py-2 text-center">
                  <input
                    type="text"
                    value={newConnection.connection_type}
                    onChange={(e) =>
                      handleNewConnectionChange(
                        "connection_type",
                        e.target.value
                      )
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </td>
                <td className="py-2 text-center">
                  <div className="flex items-center">
                    <input
                      type="number"
                      value={
                        newConnection.rps_limit !== null
                          ? newConnection.rps_limit
                          : ""
                      }
                      onChange={(e) =>
                        handleNewConnectionChange(
                          "rps_limit",
                          e.target.value !== ""
                            ? parseInt(e.target.value)
                            : null
                        )
                      }
                      className="w-full p-2 border border-gray-300 rounded"
                      disabled={newConnection.rps_limit === null}
                    />
                    <input
                      type="checkbox"
                      checked={newConnection.rps_limit === null}
                      onChange={(e) =>
                        handleNewConnectionChange(
                          "rps_limit",
                          e.target.checked ? null : newConnection.rps_limit || 0
                        )
                      }
                      className="mr-2"
                    />
                    <label className="mr-4">Infinite</label>
                  </div>
                </td>
                <td className="py-2 text-center">
                  <select
                    value={newConnection.enabled ? "Yes" : "No"}
                    onChange={(e) =>
                      handleNewConnectionChange(
                        "enabled",
                        e.target.value === "Yes"
                      )
                    }
                    className="w-full p-2 border border-gray-300 rounded"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
                <td className="py-2 text-center">
                  <button
                    className="px-4 py-2 bg-green-500 text-white rounded mr-2"
                    onClick={handleAddNewConnection}
                  >
                    Add
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded"
                    onClick={() => {
                      setAdding(false);
                      setNewConnection(initialNewConnection);
                    }}
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            )}
            {filteredConnections.map((conn) => (
              <tr
                key={conn.id}
                className={`hover:bg-gray-100 cursor-pointer ${
                  editing === conn.id ? "bg-gray-200" : ""
                }`}
                onClick={() => handleRowClick(conn.id!)}
                ref={editing === conn.id ? editingRowRef : null}
              >
                <td className="py-2 text-center">{conn.network}</td>
                <td className="py-2 text-center max-w-xs truncate">
                  {editing === conn.id ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <input
                        type="text"
                        value={conn.host}
                        onChange={(e) =>
                          handleInputChange(conn.id!, "host", e.target.value)
                        }
                        className="w-full p-2 border border-gray-300 rounded"
                        autoFocus
                      />
                    </div>
                  ) : (
                    <span title={conn.host}>{conn.host}</span>
                  )}
                </td>
                <td className="py-2 text-center">
                  {editing === conn.id ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <input
                        type="text"
                        value={conn.connection_type}
                        onChange={(e) =>
                          handleInputChange(
                            conn.id!,
                            "connection_type",
                            e.target.value
                          )
                        }
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                  ) : (
                    conn.connection_type
                  )}
                </td>
                <td className="py-2 text-center">
                  {editing === conn.id ? (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="flex items-center"
                    >
                      <input
                        type="number"
                        value={conn.rps_limit !== null ? conn.rps_limit : ""}
                        onChange={(e) =>
                          handleInputChange(
                            conn.id!,
                            "rps_limit",
                            e.target.value !== ""
                              ? parseInt(e.target.value)
                              : null
                          )
                        }
                        className="w-full p-2 border border-gray-300 rounded"
                        disabled={conn.rps_limit === null}
                      />
                      <input
                        type="checkbox"
                        checked={conn.rps_limit === null}
                        onChange={(e) =>
                          handleInputChange(
                            conn.id!,
                            "rps_limit",
                            e.target.checked ? null : conn.rps_limit || 0
                          )
                        }
                        className="mr-2"
                      />
                      <label className="mr-4">Infinite</label>
                    </div>
                  ) : conn.rps_limit !== null ? (
                    conn.rps_limit
                  ) : (
                    "Infinite"
                  )}
                </td>
                <td className="py-2 text-center">
                  {editing === conn.id ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <select
                        value={conn.enabled ? "Yes" : "No"}
                        onChange={(e) =>
                          handleInputChange(
                            conn.id!,
                            "enabled",
                            e.target.value === "Yes"
                          )
                        }
                        className="w-full p-2 border border-gray-300 rounded"
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  ) : conn.enabled ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </td>
                {editing === conn.id && (
                  <td className="py-2 text-center">
                    <button
                      className={`px-4 py-2 bg-green-500 text-white rounded ${
                        !changesMade ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => handleSaveChanges(conn)}
                      disabled={!changesMade}
                    >
                      Save
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Card } from "@material-tailwind/react";
import { api_url } from "../../index";
import axios from "axios";
import { AlertDone } from "../../components/request-response";

export default function SaveFeatureFlagsView() {
  const [featureFlags, setFeatureFlags] = useState([]);
  const [configList, setConfigList] = useState([]);
  const [selectedFlag, setSelectedFlag] = useState(null);
  const [createFlag, setCreateFlag] = useState(false);
  const [selectedIndexer, setSelectedIndexer] = useState("");
  const [selectedTransformer, setSelectedTransformer] = useState("");
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be excecuted");
  const [status, setStatus] = useState(400);
  const [searchQuery, setSearchQuery] = useState("");

  const handleCloseAlert = () => {
    setShowExec(false);
  };

  const togglePicker = () => {
    setCreateFlag(!createFlag);
  };

  const fetchConfigs = async () => {
    const options = {
      method: "GET",
      url: `${api_url}manager/configs/all`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };

    try {
      const response = await axios.request(options);
      setConfigList(response.data);
    } catch (error) {
      console.log(error);
      setConfigList([]);
    }
  };

  const fetchFlags = async () => {
    const options = {
      method: "GET",
      url: `${api_url}manager/flags/all`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };

    try {
      const response = await axios.request(options);
      setFeatureFlags(response.data);
    } catch (error) {
      console.log(error);
      setFeatureFlags([]);
    }
  };

  const handleUpsertFlag = async (e) => {
    e.preventDefault();
    const message = {
      message: {
        data: JSON.stringify(selectedFlag),
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}manager/flags/save`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    try {
      const response = await axios.request(options);
      setMessage(`${response.data} \n Actualization may take up to 5 minutes.`);
      setStatus(response.status);
      setShowExec(true);
      await fetchFlags();
    } catch (error) {
      setMessage(`${error.response.data}`);
      setStatus(error.response.status);
      setShowExec(true);
    }
    setCreateFlag(false);
  };

  useEffect(() => {
    fetchFlags();
    fetchConfigs();
  }, []);

  const handleFlagChange = (field, value) => {
    setSelectedFlag({ ...selectedFlag, [field]: value });
  };

  const openModalForNewFlag = () => {
    setSelectedIndexer('_internal');
    setSelectedTransformer('do_config_update');
    setSelectedFlag({
      indexer: "_internal",
      transformer: "do_config_update",
      flag: "rosie",
      value: "enabled",
    });
    setCreateFlag(true);
  };

  const openModalForEdit = (flag) => {
    setSelectedIndexer(flag.indexer);
    setSelectedTransformer(flag.transformer);
    setSelectedFlag(flag);
    setCreateFlag(true);
  };

  const indexers = Array.from(
    new Set(configList?.map((item) => item.indexer) ?? [])
  );

  const transformers = configList
    .filter((item) => item.indexer === selectedIndexer)
    .map((item) => item.transformer);

  const filteredFlags = featureFlags.filter(
    (flag) =>
      (flag.indexer &&
        flag.indexer.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (flag.transformer &&
        flag.transformer.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (flag.flag && flag.flag.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <div className="flex justify-between items-center">
        <button
          onClick={openModalForNewFlag}
          className="px-4 py-2 bg-green-300 text-white rounded-md hover:bg-green-400 transition-colors"
        >
          Create Flag
        </button>
        {createFlag && (
          <div>
            <div
              className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              onClick={togglePicker}
            >
              <div
                className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
                onClick={(e) => e.stopPropagation()}
                style={{ zIndex: 1000 }}
              >
                <div className="mt-3 text-center">
                  <div className="space-y-4 p-4">
                    <p className="font-semibold text-center">
                      {selectedFlag?.id ? "Edit Flag" : "Create New Flag"}
                    </p>
                    <form
                      onSubmit={handleUpsertFlag}
                      className="flex flex-col gap-4"
                    >
                      <div>
                        <label
                          htmlFor="indexer"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Indexer
                        </label>
                        <select
                          id="indexer"
                          value={selectedIndexer}
                          onChange={(e) => {
                            setSelectedIndexer(e.target.value);
                            handleFlagChange("indexer", e.target.value);
                          }}
                          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          {indexers.map((indexer) => (
                            <option key={indexer} value={indexer}>
                              {indexer}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="transformer"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Transformer
                        </label>
                        <select
                          id="transformer"
                          value={selectedTransformer}
                          onChange={(e) => {
                            setSelectedTransformer(e.target.value);
                            handleFlagChange("transformer", e.target.value);
                          }}
                          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          {transformers.map((transformer) => (
                            <option key={transformer} value={transformer}>
                              {transformer}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="flag"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Flag
                        </label>
                        <select
                          value={selectedFlag.flag}
                          onChange={(e) =>
                            handleFlagChange("flag", e.target.value)
                          }
                          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="rosie">Rosie</option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="value"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Value
                        </label>
                        <select
                          value={selectedFlag.value}
                          onChange={(e) =>
                            handleFlagChange("value", e.target.value)
                          }
                          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="enabled">Enabled</option>
                          <option value="disabled">Disabled</option>
                        </select>
                      </div>

                      <Button type="submit" color="green" ripple={true}>
                        Save
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <h1 className="text-3xl font-semibold my-6 text-center">
          Feature Flags
        </h1>
      </div>

      <div className="w-full">
        <div className="mb-4">
          <div className="relative flex items-center">
            <input
              type="text"
              placeholder="Search Flags"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 pl-10 border border-gray-300 rounded focus:outline-none focus:border-green-200"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-5 w-5 absolute left-3 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
        </div>

        {filteredFlags.map((flag) => (
          <div
            key={flag}
            className="mb-4 p-4 border shadow bg-green-200 p-4 rounded-md cursor-pointer mb-4"
            onClick={() => openModalForEdit(flag)}
          >
            <p>Indexer: {flag.indexer}</p>
            <p>Transformer: {flag.transformer}</p>
            <p>Flag: {flag.flag}</p>
            <p>Value: {flag.value}</p>
          </div>
        ))}
      </div>

      {showExec && (
        <AlertDone
          message={message}
          status={status}
          open={showExec}
          onClose={handleCloseAlert}
        />
      )}
    </Card>
  );
}

import "./App.css";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar";
import MainView from "./views/mainview";
import IndexConfigView from "./views/configs/index";
import IndexOscarView from "./views/oscar/index";
import IndexScriptsView from "./views/scripts/index";
import IndexCustomerViews from "./views/customers/index";
import IndexLogsView from "./views/logger/index";
import IndexUtilsView from "./views/utils/index";
import IndexRosieView from "./views/rosie/index";
import IndexTestingView from "./views/testing/index";
import IndexConsoleView from "./views/console/index";
import NotAuthorized from "./views/401";
import { NotFoundLogged, NotFoundUnlogged } from "./views/404";
import Footer from "./components/footer";
import Login from "./views/login";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@material-tailwind/react";
import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";

export default function App() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  let isValidUser = sessionStorage.getItem("valid");
  let vaUser = sessionStorage.getItem("user");

  useEffect(() => {
    if (isAuthenticated) {
      // Get the access token silently
      const getAccessToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          sessionStorage.setItem("jwt", accessToken);
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      };

      getAccessToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <Spinner />
      </div>
    );
  }
  if (user !== undefined && user !== null && vaUser === null) {
    const isEmailValid = user.email.split("@")[1] === "indexing.co";
    if (isAuthenticated && isEmailValid && user.email_verified) {
      sessionStorage.setItem("valid", "true");
      sessionStorage.setItem("user", `${user.name}`);
    } else {
      sessionStorage.setItem("valid", "false");
    }
  }
  if (
    sessionStorage.getItem("valid") === "false" ||
    sessionStorage.getItem("valid") === null
  ) {
    isValidUser = false;
  } else {
    isValidUser = true;
  }
  vaUser = sessionStorage.getItem("user");
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <div className="flex">
          {/* Sidebar with fixed width (conditionally rendered) */}
          {isValidUser && (
            <div className="w-1/4">
              <Sidebar name={vaUser} />
            </div>
          )}

          {/* Main Content occupying remaining space */}
          <div className={`flex-1 ${isValidUser ? "w-3/4" : ""}`}>
            {/* Scrollable div with fixed size */}
            <div className="w-full h-full overflow-auto">
              <Routes>
                {/*If the user is valid show the main view */}
                {isValidUser && (
                  <Route path="/dashboard" element={<MainView />} />
                )}
                {isValidUser && <Route path="/" element={<MainView />} />}
                {isValidUser && (
                  <Route
                    path="/configs/:configAction"
                    element={<IndexConfigView />}
                  />
                )}
                {isValidUser && (
                  <Route
                    path="/scripts/:scriptsAction"
                    element={<IndexScriptsView />}
                  />
                )}
                {isValidUser && (
                  <Route
                    path="/customers/:customerAction"
                    element={<IndexCustomerViews />}
                  />
                )}
                {isValidUser && (
                  <Route path="/logs/:logsAction" element={<IndexLogsView />} />
                )}
                {isValidUser && (
                  <Route
                    path="/utils/:utilsAction"
                    element={<IndexUtilsView />}
                  />
                )}
                {isValidUser && (
                  <Route
                    path="/rosie/:rosieAction"
                    element={<IndexRosieView />}
                  />
                )}
                {isValidUser && (
                  <Route
                    path="/testing/:testingAction"
                    element={<IndexTestingView />}
                  />
                )}
                {isValidUser && (
                  <Route
                    path="/oscar/:oscarAction"
                    element={<IndexOscarView />}
                  />
                )}
                {isValidUser && (
                  <Route
                    path="/console/:consoleAction"
                    element={<IndexConsoleView />}
                  />
                )}
                {isValidUser && <Route path="*" element={<NotFoundLogged />} />}

                {/*If the user is not valid show the unauthorized or login view */}
                {!isValidUser && <Route path="/" element={<Login />} />}
                {!isValidUser && (
                  <Route path="/dashboard" element={<NotAuthorized />} />
                )}
                {!isValidUser && (
                  <Route path="*" element={<NotFoundUnlogged />} />
                )}
              </Routes>
            </div>
          </div>
        </div>
        <Analytics />
        {!isValidUser && <Footer />}
      </div>
    </BrowserRouter>
  );
}

import React, { useState } from "react";
import { Button, Card } from "@material-tailwind/react";
import axios from "axios";
import { AlertDone } from "../../components/request-response";
import { api_url } from "../../index";
import ConfigSelector from "../../components/configselector";

export default function SaveConfigView() {
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be excecuted");
  const [status, setStatus] = useState(400);
  const [config, setConfig] = useState("");
  const [selectConfig, setSelectConfig] = useState(false);

  const handleCloseAlert = () => {
    setShowExec(false);
  };

  const handleConfig = (config) => {
    setConfig(config);
  };

  const handleSelectConfig = () => {
    setSelectConfig(!selectConfig);
  };

  const handleForceSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      config: config,
      test: true,
    };
    const message = {
      message: {
        data: JSON.stringify(payload),
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}manager/configs/save`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setMessage(response.data);
        setStatus(response.status);
        setShowExec(true);
      })
      .catch(function (error) {
        setMessage(`${error.response.data}`);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };

  const handleform = async (e) => {
    e.preventDefault();
    const payload = {
      config: config,
    };
    const message = {
      message: {
        data: JSON.stringify(payload),
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}manager/configs/save`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setMessage(response.data);
        setStatus(response.status);
        setShowExec(true);
      })
      .catch(function (error) {
        setMessage(`${error.response.data}`);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };
  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto flex items-center">
      <form onSubmit={handleform} className="flex flex-col w-full max-w-lg">
        {selectConfig && (
          <ConfigSelector
            onConfig={handleConfig}
            onClose={handleSelectConfig}
          />
        )}
        <h1 className="text-3xl font-semibold my-6 text-center">Add Config</h1>
        <div className="w-full justify-center items-center">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="config"
          >
            Config
          </label>
          <textarea
            className="resize-none appearance-none block w-full h-[40vh] bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            id="config"
            placeholder="Config"
            value={config}
            onChange={(e) => setConfig(e.target.value)}
            rows={12}
          ></textarea>
          <p className="text-green-200 text-xs italic">
            Please fill out this field.
          </p>
        </div>
        <div className="flex justify-center mt-4">
          <div className="mr-2">
            <Button
              variant="gradient"
              color="orange"
              onClick={handleSelectConfig}
            >
              Select Config
            </Button>
          </div>
          <div className="mr-2 ml-2">
            <Button variant="gradient" color="light-green" type="submit">
              Submit Config
            </Button>
          </div>
          <div className="ml-2">
            <Button variant="gradient" color="red" onClick={handleForceSubmit}>
              Force Submit
            </Button>
          </div>
        </div>
      </form>
      {showExec && (
        <AlertDone
          message={message}
          status={status}
          open={showExec}
          onClose={handleCloseAlert}
        />
      )}
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import { api_url } from "../../index";
import axios from "axios";
import { Card } from "@material-tailwind/react";

export default function AllCustomersView() {
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomerid, setSelectedCustomerid] = useState("");

  useEffect(() => {
    const fetchCustomers = async () => {
      const options = {
        method: "GET",
        url: `${api_url}manager/customers/all`,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
        },
      };

      try {
        const response = await axios.request(options);
        setCustomers(response.data);
      } catch (error) {
        console.log(error);
        setCustomers([]);
      }
    };

    fetchCustomers();
  }, []);

  const handleCustomerClick = (customer) => {
    if (selectedCustomerid === customer._id) {
      setSelectedCustomerid("");
    } else {
      setSelectedCustomerid(customer._id);
    }
  };

  const filteredCustomers = customers.filter(
    (customer) =>
      customer.indexer &&
      customer.indexer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <h1 className="text-3xl font-semibold my-6 text-center">Customers</h1>
      <div className="w-full">
        <div className="mb-4">
          <div className="relative flex items-center">
            <input
              type="text"
              placeholder="Search Customer"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 pl-10 border border-gray-300 rounded focus:outline-none focus:border-green-200"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-5 w-5 absolute left-3 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
        </div>

        {filteredCustomers.map((customer) => (
          <div
            key={customer._id}
            className="bg-green-200 p-4 rounded-md cursor-pointer mb-4"
          >
            <div
              className="flex items-center"
              onClick={() => handleCustomerClick(customer)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5l7 7-7 7M5 5l7 7-7 7"
                />
              </svg>
              <p className="text-lg font-semibold">{customer.name}</p>
            </div>
            {selectedCustomerid === customer._id && (
              <div className="mt-2 p-4 rounded-md pointer-events-none">
                <h2 className="text-lg font-semibold">{customer.name}</h2>
                <p className="text-gray-700">Indexer: {customer.indexer}</p>
                <p className="text-gray-700">Api Key: {customer.apiKey}</p>
                <p className="text-gray-700">
                  Management Key: {customer.managementKey}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
}

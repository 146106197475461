/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Card, Spinner } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";
import { AlertDone } from "../../components/request-response";
import { pacemakers } from "./utils";

export default function RpcMetricsView() {
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be executed");
  const [status, setStatus] = useState(400);
  const [selectedPacemaker, setSelectedPacemaker] = useState("");
  const [selectedPacemaker_2, setSelectedPacemaker_2] = useState("");
  const [rpcUrl, setRpcUrl] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    pacemaker: selectedPacemaker,
    testing_id: "",
    url: "",
    time: new Date(),
    recordsInPeriod: 0,
    actualTimeSpan: 0,
    throughput: 0,
  });

  useEffect(() => {
    fetchMetrics();
  }, [selectedPacemaker]);

  const handleCloseAlert = () => {
    setShowExec(false);
  };

  const triggerTest = async () => {
    if (selectedPacemaker_2 && rpcUrl) {
      setIsLoading(true);
      const payload = {
        pacemaker: selectedPacemaker_2,
        url: rpcUrl,
        ...(start && { start: Number(start) }),
        ...(end && { end: Number(end) }),
      };
      const message = {
        message: {
          data: JSON.stringify(payload),
        },
      };
      const options = {
        method: "POST",
        url: `https://test.indexing.co/testing/rpc`,
        data: message,
      };
      await axios
        .request(options)
        .then(function (response) {
          setMessage(response.data);
          setStatus(response.status);
          setShowExec(true);
          setShowModal(false);
          setSelectedPacemaker(selectedPacemaker_2);
        })
        .catch(function (error) {
          setMessage(`Error: ${error.response?.data || "Unknown error"}`);
          setStatus(error.response.status);
          setShowExec(true);
        });
      setIsLoading(false);
    }
  };

  const fetchMetrics = async () => {
    if (selectedPacemaker) {
      const payload = {
        pacemaker: selectedPacemaker,
      };
      const message = {
        message: {
          data: JSON.stringify(payload),
        },
      };
      const options = {
        method: "POST",
        url: `${api_url}manager/rpc/metrics`,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
        },
        data: message,
      };
      await axios
        .request(options)
        .then(function (response) {
          setData(response.data);
          setShowExec(false);
        })
        .catch(function (error) {
          setMessage(`Error: ${error.response?.data || "Unknown error"}`);
          setStatus(error.response.status);
          setShowExec(true);
        });
    }
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex space-x-2">
          <button
            onClick={() => {
              setShowModal(true);
              setIsLoading(false);
            }}
            className="px-4 py-2 rounded-md bg-green-200"
          >
            New test
          </button>
          <button
            onClick={fetchMetrics}
            className="px-4 py-2 rounded-md bg-green-200"
          >
            Reload
          </button>
        </div>
        {showModal && (
          <div>
            <div
              className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              onClick={() => setShowModal(!showModal)}
            >
              <div
                className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
                onClick={(e) => e.stopPropagation()}
                style={{ zIndex: 1000 }}
              >
                <div className="mt-3 text-center">
                  <div className="space-y-4 p-4">
                    <p className="font-semibold text-center">Trigger test</p>

                    <div>
                      <label
                        htmlFor="indexer"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Pacemaker
                      </label>
                      <select
                        id="pacemaker"
                        value={selectedPacemaker_2}
                        onChange={(e) => {
                          setSelectedPacemaker_2(e.target.value);
                        }}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      >
                        {pacemakers.map((pacemaker) => (
                          <option key={pacemaker} value={pacemaker}>
                            {pacemaker}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="flag"
                        className="block text-sm font-medium text-gray-700"
                      >
                        RPC URL
                      </label>
                      <input
                        type="text"
                        value={rpcUrl}
                        onChange={(e) => setRpcUrl(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="value"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Start Optional
                      </label>
                      <input
                        type="number"
                        value={start}
                        onChange={(e) => setStart(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="value"
                        className="block text-sm font-medium text-gray-700"
                      >
                        End Optional
                      </label>
                      <input
                        type="number"
                        value={end}
                        onChange={(e) => setEnd(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    {isLoading ? (
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    ) : (
                      <Button onClick={triggerTest} color="green" ripple={true}>
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <h1 className="text-3xl font-semibold">RPC Metrics</h1>
      </div>

      <div className="flex gap-4 mb-4">
        <select
          value={selectedPacemaker}
          onChange={(e) => setSelectedPacemaker(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="">Select Pacemaker</option>
          {pacemakers.map((pacemaker) => (
            <option key={pacemaker} value={pacemaker}>
              {pacemaker}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-center mb-4">
        <div style={{ textAlign: "center", margin: "auto", fontSize: "2rem" }}>
          <div>Throughput (beats/second)</div>
          <div style={{ fontWeight: "bold" }}>{data.throughput.toFixed(2)}</div>
        </div>
      </div>

      <div className="text-center mb-4">
        <p>
          <strong>RPC URL:</strong> {data.url}
        </p>
        <p>
          <strong>Last Record Time:</strong>{" "}
          {new Date(data.time).toLocaleString()} UTC
        </p>
        <p>
          <strong>Records in Period:</strong> {data.recordsInPeriod}
        </p>
        <p>
          <strong>Total Time Elapsed (seconds):</strong>{" "}
          {data.actualTimeSpan.toFixed(2)}
        </p>
        <p>
          <strong>Testing ID:</strong> {data.testing_id}
        </p>
      </div>

      {showExec && (
        <AlertDone
          message={message}
          status={status}
          open={showExec}
          onClose={handleCloseAlert}
        />
      )}
    </Card>
  );
}
